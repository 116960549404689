import { NavLink } from 'react-router-dom';

const DesktopNavigation = () => {
  const navItems = [
    { path: '/', label: 'Pregled' },
    { path: '/near-by', label: 'Mapa' },
    { path: '/kuponi', label: 'Moji kuponi' },
    { path: '/yettel-petak', label: 'Y. Petak' },
    { path: '/profil', label: 'Profil' }
  ];

  return (
    <nav>
      <ul className="yettel-header__navigation d-flex">
        {navItems.map(item => (
          <DesktopNavigationElement label={item.label} path={item.path} />
        ))}
      </ul>
    </nav>
  );
};

const DesktopNavigationElement: React.FC<{ label: string; path: string }> = ({ label, path }) => {
  return (
    <li>
      <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to={path}>
        {label}
      </NavLink>
    </li>
  );
};

export default DesktopNavigation;
