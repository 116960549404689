import { useMatch } from 'react-router-dom';
import SearchBarTrigger from './SearchBarTrigger';
import LocationListContainer from './LocationListContainer';
import DesktopNavigation from './DesktopNavigation';
import '../scss/yettel-header.scss';

interface Props {
  navigateToHomepage: Function;
}

const HeaderDesktop: React.FC<Props> = ({ navigateToHomepage }) => {
  const isHomePage = useMatch('/');
  const isPrivacyPage = useMatch('/profil/privatnost');

  return (
    <header className={`yettel-header bg-primary-color ${!isHomePage && 'mb-5'}`}>
      <div className="yettel-header__container container d-flex align-items-center">
        <div className="yettel-header__logo-container">
          <div className="cursor-pointer" onClick={() => navigateToHomepage()}>
            <span className="d-block color-secondary-color font-yettel-bold">Y. Shopping</span>
          </div>
        </div>

        {!isPrivacyPage && <DesktopNavigation />}

        <div className="yettel-header__searchbar-container">
          {isHomePage && <LocationListContainer />}
          <SearchBarTrigger />
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
