import { useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import GlobalWrapper from './GlobalWrapper';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import MobileNavigation from './MobileNavigation';
import LoadUtilityClassesLast from './LoadUtilityClassesLast';
import Authorization from './Authorization';
import Modals from './Modals';
import { last } from 'lodash';
import { LOGIN_REDIRECT } from '../constants/config';

interface Props {
  children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const [isAuthorizing, setIsAuthorizing] = useState(true);

  const isRedirectPage = useMatch(last(LOGIN_REDIRECT.split('/')) as string);
  const isPrivacyPolicyPage = useMatch('/profil/privatnost');

  return (
    <>
      {isAuthorizing && <Authorization setIsAuthorizing={setIsAuthorizing} />}
      {!isAuthorizing && (
        <>
          <GlobalWrapper>
            <Header />
            <Content>
              {children ?? ''}
              <Outlet />
            </Content>

            <Footer />

            {!isPrivacyPolicyPage && <MobileNavigation />}
            <LoadUtilityClassesLast />
          </GlobalWrapper>

          <Modals />
        </>
      )}

      {isRedirectPage && <Outlet />}
    </>
  );
};

export default Layout;
