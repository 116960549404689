import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import {
  logIn,
  getUserConsents,
  pingHelloWorld,
  userHasNalogCookie,
  persistUserConsentsInLocalStorage,
  shouldRedirectToPrivacySettings,
  getAndStoreUserDataBySession
} from '../utils/auth';
import { useEffect } from 'react';
import { LOGIN_REDIRECT } from '../constants/config';
import LoadingIndicator from './LoadingIndicator';
import { last } from 'lodash';

interface Props {
  setIsAuthorizing: React.Dispatch<React.SetStateAction<boolean>>;
}

const privacySettingsPage = '/profil/privatnost';

const Authorization: React.FC<Props> = ({ setIsAuthorizing }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirected = searchParams.get(SEARCH_PARAMS.REDIRECTED) === 'true';
  const hasNalogCookie = userHasNalogCookie();

  const currentPage = last(window.location.pathname.split('/'));
  const redirectPage = last(LOGIN_REDIRECT.split('/'));

  // Utilities.
  const getStoreAndCheckConsents = async () => {
    await getUserConsents().then(response => persistUserConsentsInLocalStorage(response.data));

    const shouldRedirect = shouldRedirectToPrivacySettings();
    // Eject from here and go to privacy settings.
    if (shouldRedirect && currentPage !== privacySettingsPage) {
      navigate(`/profil/privatnost?callback=${location.pathname}`);
    }

    return;
  };

  // 1. Start a fresh flow which may end up going to the login redirect page and then back here again.
  const initFreshAuthorizationFlow = async () => {
    try {
      await pingHelloWorld().then(async response => {
        if (response.session === null) {
          // Start the login process (go to Nalog and then back to the login redirect page).
          logIn();
        } else {
          // The user is logged in, fetch consents and user data.
          await getStoreAndCheckConsents();
          await getAndStoreUserDataBySession();

          // END OF FLOW.
          setIsAuthorizing(false);
        }
      });
    } catch (e) {
      // If an error occurs and the user has a cookie, try to log him in "the long way round".
      logIn();
    }
  };

  // 2. Handle user that just got here from the login redirect page.
  const getConsentsAndCleanUpSearchParams = async () => {
    try {
      await getStoreAndCheckConsents();

      searchParams.delete(SEARCH_PARAMS.REDIRECTED);
      setSearchParams(searchParams);

      // END OF FLOW.
      setIsAuthorizing(false);
    } catch (e) {
      throw new Error('Error during getUserDataAndConsentsAndCleanUpSearchParams');
    }
  };

  // Main login logic.
  useEffect(() => {
    // Don't do anything on the login redirect page or the privacy settings page.
    if (currentPage === redirectPage) {
      return;
    }

    if (!hasNalogCookie) {
      // END OF FLOW.
      setIsAuthorizing(false);
    } else {
      if (redirected) {
        // 2. Handle user that just got here from the login redirect page.
        getConsentsAndCleanUpSearchParams();
      } else {
        // 1. Start a fresh flow which may end up going to the login redirect page and then back here again.
        initFreshAuthorizationFlow();
      }
    }
  }, [location.pathname]);

  return (
    <div className="d-flex justify-content-center align-items-center full-height">
      <LoadingIndicator />
    </div>
  );
};

export default Authorization;
