import { useQuery } from "@tanstack/react-query";
import { Category } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { useState } from "react";
import UserSelectedCategoryListModal from "./UserSelectedCategoryListModal";
import UserSelectedCategoryPreview from "./UserSelectedCategoryPreview";
import { STALE_TIME } from "../constants/staleTime";

interface Props {
  selectedCategoryIds: string[]
}

const UserSelectedCategoryList: React.FC<Props> = ({
  selectedCategoryIds = []
}) => {
  const [showCategoryList, setShowCategoryList] = useState(false);

  const { data } = useQuery<Category[]>({
    queryKey: [QUERY_KEYS.GET_CATEGORIES],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        sort: "abc",
        limit: "100",
      });

      const response = await callPublicAPI<Category[]>("GET", "/categories", searchParams);
      return response.data;
    },
    staleTime: STALE_TIME.HOUR
  });

  return (
    <>
      <ul className="mb-2">
        {data && data.filter((category) => selectedCategoryIds.includes(category.id)).map((category) => (
          <UserSelectedCategoryPreview key={category.id} category={category} selectedCategories={selectedCategoryIds} />
        ))}
      </ul>
      <button onClick={() => setShowCategoryList(true)} className="cursor-pointer d-block mx-auto text-decoration-underline">+ Dodaj kategoriju</button>

      {showCategoryList && data && (
        <UserSelectedCategoryListModal categories={data} selectedCategoryIds={selectedCategoryIds} setShowCategoryList={setShowCategoryList}/>
      )}
    </>
  );
}

export default UserSelectedCategoryList;
